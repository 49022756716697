<script>
import { mapActions, mapGetters } from "vuex";
import Field from "@/components/Field";
import {getImage} from "@/services/helpers";
export default {
  name: "Questionnaire",
  components: {
    Field,
  },
  data: function () {
    return {
      forms: 1,
      activePanel: 0,
      loading: true,
    }
  },
  computed: {
    ...mapGetters("User", {
      isLoggedIn: "isLoggedIn",
    }),
    ...mapGetters("Task", {
      description: "getDescription",
      name: "getName",
      security: "getSecureType",
      fieldsSchema: "getFieldsSchema",
      type: "getType",
      taskId: "getTaskId",
      taskImg: "getTaskImg",
      fields: "getFields",
      counterCurrent: "getCounterCurrent",
      counterTotal: "getCounterTotal",
    }),
    isMultipleForms() {
      return this.type === 'multiple_forms'
    }
  },
  async created() {
    if (!this.type)
      try {
        await this.userTask()
        if (this.isMultipleForms) {
          await this.$nextTick()
          const lengthsOfFields = this.fields.map((f) => Array.isArray(f.value) ? f.value.length : 0)
          this.forms = Math.max(...lengthsOfFields) || 1
        }
      } catch (e) {
        await this.$router.push({name: "Expired"})
      }
    this.loading = false
  },
  methods: {
    getImage,
    ...mapActions("Task", {
      firstTask: "firstTask",
      previousTask: "previousTask",
      completeTask: "completeTask",
      task: "task",
      userTask: "userTask",
      completeTaskSecure: "completeTaskSecure",
      previousTaskSecure: "previousTaskSecure",
      taskSecure: "taskSecure",
    }),
    async back() {
      this.loading = true
      if (this.security === "common") {
        await this.previousTask()
      } else if (this.security === "secure") {
        await this.previousTaskSecure()
      } else {
        await this.previousTask()
      }
      this.loading = false
    },
    async forward() {
      if (!this.validateForms()) return;
      this.loading = true
      if (this.security === "common") {
        await this.completeTask()
      } else if (this.security === "secure") {
        await this.completeTaskSecure()
      } else {
        await this.completeTask()
      }
      this.loading = false
    },
    onDeleteForm(formIndex) {
      this.$store.commit("Task/DELETE_FORM", formIndex)
      this.forms--
    },
    validateForms() {
      if (this.isMultipleForms) {
        if (this.forms < 1) return false
        let valid = true
        this.$refs.form.forEach((form)=> {
          valid = form.validate()
        })
        if (!valid) return false
      } else
        if (!this.$refs.form.validate())
          return false
      return true
    },
    onEnter(index) {
      if (index === this.fieldsSchema.length - 1)
        this.forward()
    }
  },
}
</script>

<template>
  <div>
    <v-overlay v-if="loading">
      <v-progress-circular
        indeterminate
        size="64"
      />
    </v-overlay>
    <v-container
      v-else
      class="application__wrapper"
    >
      <v-row justify="center">
        <v-col
          cols="12"
          sm="10"
          md="8"
          lg="6"
          xl="4"
        >
          <v-img
            v-if="taskImg"
            :src="getImage(taskImg)"
            contain
            style="margin-bottom: 30px;"
          />
          <p
            class="description"
            v-html="description"
          />
          <v-expansion-panels
            v-if="isMultipleForms"
            v-model="activePanel"
            focusable
          >
            <v-expansion-panel
              v-for="(_, i) in forms"
              :key="`form-${i}`"
            >
              <v-expansion-panel-header>
                {{ fields[0].value ? fields[0].value[i] || `Опыт работы #${_}` : `Опыт работы #${_}` }}
              </v-expansion-panel-header>
              <v-expansion-panel-content
                class="pt-3"
                eager
              >
                <v-form
                  ref="form"
                  lazy-validation
                  @submit.prevent
                >
                  <template
                    v-for="(schema, index) in fieldsSchema"
                  >
                    <p
                      v-if="!!schema.name && schema.name !== 'NaN'"
                      :key="`name-${index}-${i}`"
                      class="name"
                    >
                      {{ schema.name }}
                    </p>
                    <Field
                      :key="`field-${index}-${i}`"
                      :schema="schema"
                      :index="index"
                      multiple-forms
                      :multiple-forms-index="i"
                    />
                    <v-divider
                      v-if="index !== fieldsSchema.length - 1"
                      :key="`divider-${index}-${i}`"
                      class="mb-5"
                    />
                  </template>
                  <v-btn
                    v-if="!(i === 0 && forms === 1)"
                    depressed
                    tile
                    right
                    style="left: 50%;transform: translateX(-50%);"
                    @click="onDeleteForm(i)"
                  >
                    Удалить
                  </v-btn>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-btn
              depressed
              tile
              class="mt-2"
              style="border-radius: 0 !important;"
              color="primary"
              @click="forms++"
            >
              Добавить
            </v-btn>
          </v-expansion-panels>
          <v-form
            v-else
            ref="form"
            lazy-validation
            @submit.prevent
          >
            <template
              v-for="(schema, index) in fieldsSchema"
            >
              <p
                v-if="!!schema.name && schema.name !== 'NaN'"
                :key="`name-${index}`"
                class="name"
              >
                {{ schema.name }}
              </p>
              <Field
                v-if="schema.type !== 'nan'"
                :key="`field-${index}`"
                :schema="schema"
                :index="index"
                @enter="onEnter(index)"
              />
              <v-divider
                v-if="schema.type !== 'nan' && index !== fieldsSchema.length - 1"
                :key="`divider-${index}`"
                class="mb-5"
              />
            </template>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <div class="toolbar">
      <v-btn
        v-show="type !== 'start_task'"
        depressed
        tile
        large
        @click="back"
      >
        Назад
      </v-btn>
      <v-spacer />
      <v-stepper
        v-if="counterCurrent && counterTotal"
        v-model="counterCurrent"
        flat
      >
        <v-stepper-header>
          <v-stepper-step
            v-for="step in (counterTotal + 1)"
            :key="step"
            :step="step"
          >
            <template v-if="step === counterCurrent">
              {{ name || description }}
            </template>
          </v-stepper-step>

          <v-divider />
        </v-stepper-header>
      </v-stepper>
      <v-spacer />
      <v-btn
        v-show="type !== 'finish_task'"
        depressed
        tile
        large
        color="primary"
        @click="forward"
      >
        Далее
      </v-btn>
    </div>
  </div>
</template>

<style scoped lang="scss">
.application__wrapper {
  padding-bottom: 76px;
}
</style>